import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { useEffect, useState } from "react";
import * as Constants from '../components/Constants';
import axios from "axios";
import Loader from "../components/Loader";
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const OneBrand = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [brandName, setBrandName] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${Constants.LARAVEL_API_URL}/brand/${params.brand_slug}`).then(res => {
            if (res.data.status === 200) {
              setLoading(false)
              setProducts(res.data.brand)
              setBrandName(res.data.brand_name)
            }
        });
      }, [params]);

      let allProducts = products.map((product) => (
        <Link to={`/product/${product.slug}`} className="productItem">
          <figure>
              <img 
              src={`${Constants.LARAVEL_URL}/assets/images/products/${product.images}`} 
              alt={product.name} 
              className="productItemImg"
              />
              <h2>{product.name}</h2>
          </figure>
        </Link>
      ));

      const {t} = useTranslation();

  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('brand_page_title')} - {brandName}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li><Link to="/brands">{t('brands_page_title')}</Link></li>
              /
              <li>{brandName}</li>
          </ul>
        </div>
        <div className='brandsContent'>
          {loading ? <Loader width={400} height={400} /> : allProducts}
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default OneBrand
