import './assets/css/styles.css';
import './assets/css/response.css';
import { Routes, Route} from "react-router-dom";
import Lenis from '@studio-freight/lenis'
import { useEffect } from 'react';
import Home from './pages/Home';
import License from './pages/License';
import Contacts from './pages/Contacts';
import Brands from './pages/Brands';
import Projects from './pages/Projects';
import Solutions from './pages/Solutions';
import SolutionCategory from './pages/SolutionCategory';
import OneSolution from './pages/OneSolution';
import Services from './pages/Services';
import About from './pages/About';
import Offers from './pages/Offers';
import Category from './pages/Category';
import Catalog from './pages/Catalog';
import Product from './pages/Product';
import Subcategories from './pages/Subcategories';
import OneBrand from './pages/OneBrand';
import OneProject from './pages/OneProject';
import SearchResult from './pages/SearchResult';
import OneArticle from './pages/OneArticle';
import Articles from './pages/Articles';
import OneService from './pages/OneService';
import OneOffer from './pages/OneOffer';



function App() {
  useEffect(() => {

    const lenis = new Lenis()
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    
    requestAnimationFrame(raf)

    
    const scrollToTop = document.querySelector('.scrollTopBtn');
    scrollToTop.addEventListener('click', () => {
        lenis.scrollTo('top', {
            duration: 1,
            easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        })
    });

  })
  
  return (
    <>  
        
      <Routes>        
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/license" element={<License />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brands/:brand_slug" element={<OneBrand />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:project_slug" element={<OneProject />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:article_slug" element={<OneArticle />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/solutions/:solution_slug" element={<SolutionCategory />} />
          <Route path="/solutions/:solution_category_slug/:solution_slug" element={<OneSolution />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:service_slug" element={<OneService />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/offers/:offer_slug" element={<OneOffer />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/catalog/:category_slug" element={<Category />} />
          <Route path="/catalog/:category_slug/:subcategory_slug" element={<Subcategories />} />
          <Route path="/product/:product_slug" element={<Product />} />
          <Route path="/searchresult/:searchWord" element={<SearchResult />} />
      </Routes>
    </>
  );
}

export default App;


