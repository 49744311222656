import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import axios from "axios";
import { Link, NavLink, useParams } from 'react-router-dom';
import Loader from "../components/Loader";
import * as Constants from '../components/Constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from 'react-i18next';
import {motion, AnimatePresence} from 'framer-motion';


const Product = () => {
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [brand, setBrand] = useState({});
    const params = useParams();
    const modalRef = useRef();

    useEffect(() => {
        axios.get(`${Constants.LARAVEL_API_URL}/getProduct/${params.product_slug}`).then(res => {
            if (res.data.status === 200) {
              setLoading(false)
              setProduct(res.data.product)
              setBrand(res.data.brand)
            }
        });
      }, [params]);
    const {t} = useTranslation();

    const tabs = document.querySelectorAll('.tab_btn');
    const all_content = document.querySelectorAll('.content');

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', (e) => {
            tabs.forEach(tab => {tab.classList.remove('active')});
            tab.classList.add('active')

            var line = document.querySelector('.line');
            line.style.width = e.target.offsetWidth + "px";
            line.style.left = e.target.offsetLeft + "px";

            all_content.forEach(content=>{content.classList.remove('active')})
            all_content[index].classList.add('active')
        })

        
    })

    const onSubmit = async (data) => {
        await axios.post(`${Constants.LARAVEL_API_URL}/setPrice`, data)
        .then(response => {
            if(response.data.status === 200){
              toast.success(t('your_order_success'));
    
              reset();
              
              window.setTimeout(function () {
                modalRef.current.close();
              }, 2000);
              
            }
        })
        .catch(function (error) {
            toast.error(t('your_order_error'));
        });
      };

    const product_request_title = t('product_request_title');

    return (
        <>
        <Modal ref={modalRef}>
          <h2>{t('set_your_order_title')}</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="inputField">
                    <i class="fa-solid fa-user"></i>
                    <input                        
                        required
                        className="modalInput"
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t('modal_name')}
                        autoComplete='off'
                        {...register("name")}
                    />
                </fieldset>                    
                <fieldset className="inputField">
                    <i class="fa-solid fa-phone-volume"></i>
                    <input
                        required
                        className="modalInput"
                        type="phone"
                        id="phone"
                        name="phone"
                        autoComplete='off'
                        placeholder={t('modal_phone')}
                        {...register("phone")}
                    />
                </fieldset>
                <fieldset className="inputField">
                    <i class="fas fa-envelope"></i>
                    <input
                        className="modalInput"
                        type="email"
                        id="email"
                        name="email"
                        autoComplete='off'
                        placeholder={t('modal_email')}
                        {...register("email")}
                    />
                </fieldset>
                <fieldset className="inputField">
                    <i class="fas fa-comment"></i>
                    <textarea
                        required
                        className="modalInput modalTextarea"
                        type="text"
                        id="comment"
                        name="comment"
                        autoComplete='off'
                        placeholder={t('modal_comment')}
                        {...register("comment")}
                    />
                </fieldset>
                <input
                    className="modalInput"
                    type="hidden"
                    id="product"
                    name="product"
                    autoComplete='off'
                    value={product.name}
                    {...register("product")}
                />
                <button type="submit" className="modalInputBtn">{t('send_request_btn')}</button>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div 
                className='modalClose'
                onClick={() => {modalRef.current.close();}}
                >x</div>
            </form> 
        </Modal>
            <main>
                <Header />
                <div className="container">
                    <h1 className="commonTitle">{loading ? <Loader width={30} height={30} /> : product.name}</h1>
                    <div className="commonBreadcrumbs">
                        <ul>
                            <li><NavLink to="/"><i className="fa-solid fa-house-chimney"></i></NavLink></li>
                            /
                            <li><NavLink to="/catalog">{t('product_category_footer')}</NavLink></li>
                            /
                            <li>{product.name}</li>
                        </ul>
                    </div>
                    <div className="productOutput">
                        <div className="productGallery">
                            <figure>
                                <a href={`${Constants.LARAVEL_URL}/assets/images/products/${product.images}`} data-fancybox="group">
                                    <img src={`${Constants.LARAVEL_URL}/assets/images/products/${product.images}`} alt={product.name} />
                                </a>
                            </figure>
                        </div>
                        <div className="productContent">
                            <div className="productContentHead">
                                <div className="productContentHeadLeft">
                                    <h3>{t('product_sku')}: {product.SKU}</h3>
                                    <h3>{t('product_brand')}: <Link to={`/brands/${brand.slug}`}>{brand.name}</Link></h3>
                                </div>
                                <div className="productContentHeadRight">
                                    <div 
                                        className="headerHeadBtn"
                                        onClick={() => modalRef.current.open()}
                                    ><Trans
                                        i18nKey={product_request_title}
                                        components={{2: <br />}}
                                    ></Trans></div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="tab_box">
                                <button className="tab_btn active">{t('product_description')}</button>
                                <button className="tab_btn">{t('product_char')}</button>
                                <button className="tab_btn">{t('product_info')}</button>
                                <div className="line"></div>
                            </div>
                            <div className="content_box">
                                <div className="content active">
                                    <div className="descrProduct" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                </div>
                                <div className="content">
                                    <div className="charProduct" dangerouslySetInnerHTML={{ __html: product.char }}></div>
                                </div>
                                <div className="content">
                                    <div className="infoProduct" dangerouslySetInnerHTML={{ __html: product.usage }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Product


const Modal = forwardRef(( props, ref ) => {  
    const [openModal, setOpenModal] = useState(false);

    useImperativeHandle(ref, () => {
      return{
        open: () => setOpenModal(true),
        close: () => setOpenModal(false)
      }
    })

    return (
      <AnimatePresence>
        {openModal && (
              <>
              <motion.div 
                  initial={{
                      opacity:0,
                  }}
                  animate={{
                      opacity:1,
                      transition:{
                          duration:0.3,
                      }
                  }}
                  exit={{
                      opacity:0,
                      transition:{
                          delay:0.3,
                      },
                  }}
                  onClick={() => setOpenModal(false)}
                  className='modal-backdrop' 
              />
              <motion.div 
                  initial={{
                      scale:0,
                  }}
                  animate={{
                      scale:1,
                      transition:{
                          duration:0.3,
                      },
                  }}
                  exit={{
                      scale:0,
                      transition:{
                          delay:0.3,
                      },
                  }}
                  className='modal-content-wrapper'
              >
                  <motion.div 
                      initial={{
                          x:100,
                          opacity:0,
                      }}
                      animate={{
                          x:0,
                          opacity:1,
                          transition:{
                              delay:0.3,
                              duration:0.3,
                          },
                      }}
                      exit={{
                          x:100,
                          opacity:0,
                          transition:{
                              duration:0.3,
                          },
                      }}
                      className='modal-content'
                  >
                      {props.children}
                  </motion.div>
              </motion.div>
          </>
          )}
      </AnimatePresence>
    )
  }
);


